/** Floating label input css */

:root {
  --input-padding-x: 0.95rem;
  --input-padding-y: 0.75rem;
}

.form-label-group {
  position: relative;
}

.form-label-group > input,
.form-label-group > select,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 2px;
  display: block;
  width: 100%;
  line-height: 2.1;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder,
.form-label-group select::-webkit-select-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder,
.form-label-group select:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-input-placeholder,
.form-label-group select::-moz-select-placeholder {
  color: transparent;
}

.form-label-group input::placeholder,
.form-label-group select::placeholder {
  color: transparent;
  opacity: 0;
}
.form-control::-ms-input-placeholder,
.form-control::-ms-select-placeholder {
  color: transparent;
}
.form-label-group input:not(:placeholder-shown),
.form-label-group select:not(:placeholder-shown)
// .form-label-group .user-privilege-dropdown:not(:placeholder-shown) {
{
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}
.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group select:not(:placeholder-shown) ~ label
// .form-label-group .user-privilege-dropdown:not(:placeholder-shown) ~ label
{
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 14px;
  color: $info;
}

.form-control:disabled {
  color: $info;
}
