/* import bootstrap to set changes */
@import './_typography';
@import './variable';
@import './floatinglabel.scss';
@import '~bootstrap/scss/bootstrap';

body {
  .mobile-app-btn {
    margin-right: -5px;
    // color: white;
    // &:hover {
    //   background-color: #e6f6ff;
    //   color: $primary;
    // }
  }
  .mobile-app-hover:hover {
    background-color: #e6f6ff !important;
    color: $primary !important;
  }
  .laptop-frame {
    // position: absolute;
    // height: 100%;
    // left: 4px;
    // padding: 31px;
    // top: -10px;
    position: absolute;
    height: 100%;
    left: 3px;
    padding: 72px;
    top: -48px;
  }
  .right-icon {
    top: 12px !important;
  }
  .zipcodes-dropdown {
    .rbt-aux .rbt-close {
      font-size: 27px;
      position: absolute;
      right: 46px;
    }
    .rbt-input-main {
      padding: 6px 5px !important;
    }
    .rbt-input-multi.focus {
      border-color: $secondary !important;
      box-shadow: none !important;
      background-color: white !important;
    }
    .rbt-input-multi {
      padding-left: 6px;
    }
    .rbt-menu {
      &:hover {
        background-color: #f2f4fc;
      }
    }
    .rbt-token {
      color: #231f20;
      border-radius: 4px;
      background: #f2f4fc;
      margin: 5px 6px;
      border: 1px solid #bfc9ef;
      padding-right: 24px;
      padding-top: 8px;
      padding-bottom: 10px;
      padding-left: 11px;
    }
  }
  .admin-title .dropdown-menu.show {
    margin-top: 15px !important;
  }
  .react-tel-input {
    position: relative;
  }
  .react-tel-input .special-label {
    display: none;
  }
  .react-tel-input .form-control + div:before {
    top: 8px;
    left: 17px;
    content: 'Contact Number';
    background: none;
    font-size: 14px;
    color: $info;
    font-family: 'Futura-PT-Book';
    position: relative;
  }
  .react-tel-input .form-control:focus + div:before {
    color: $info;
    font-size: 14px;
  }
  .react-tel-input .selected-flag {
    top: -13px;
    left: 5px;
  }
  .react-tel-input .form-control {
    padding: 36.9px 14px 21.5px 58px;
    background-color: $input-bg;
    border: #ffffff;
  }
  .react-tel-input .form-control:focus {
    border: 1px solid $secondary;
    background-color: #ffffff;
  }
  .react-tel-input .form-control:hover {
    border-color: $secondary;
  }
  .react-tel-input :disabled {
    border: 0;
    background-color: #f4f4f4 !important;
  }
  .back {
    z-index: 99;
    &:focus {
      outline: none;
    }
  }
  .logo-text {
    font-size: 16px;
  }
  .outer-container {
    background: linear-gradient($secondary 50%, transparent 50%) no-repeat;
    .centered-content {
      padding: 20px;
    }
  }
  .sca-map-modal {
    background: linear-gradient(to bottom, #ff5858 0%, #790000 100%);
    .close {
      color: white;
    }
  }
  .event-modal .modal-content {
    background: none;
    border: none;
  }
  .header-title #basic-nav-dropdown {
    color: white;
  }
  .header-title .dropdown-item {
    &:active {
      background-color: $input-bg;
      color: $body-color;
    }
  }
  .header-title .dropdown-menu.show {
    margin-top: 4px !important;
  }
  .event-bg {
    background: $event-accordion-bg;
  }
  .sidebar {
    width: 370px;
    height: 610px;
    box-shadow: -10px 10px 20px #eeeeee;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .nav-link.active::before {
    content: '';
    bottom: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
    position: absolute;
    left: 0;
    width: 7px;
    right: 0;
    margin: 0 auto;
  }
  .nav-tabs .nav-link {
    border-width: 0px 0px 2px 0px;
  }
  .table-bordered td {
    border-width: 0px 0px 0px 1px;
  }

  .table-bordered td:first-child {
    border-width: 0px 0px 0px 0px;
  }
  .dropdown-toggle::after {
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin: -0.3em 0 0 0.4em;
    vertical-align: middle;
    content: '';
    border: 0.3em solid;
    border-width: 0 0.15em 0.15em 0;
    transform: rotateZ(45deg);
    color: white;
  }
  .react-tel-input .form-control:focus {
    border-color: none;
    box-shadow: none;
  }
  .hoverable {
    &:hover {
      background-color: $light;
      border-radius: 0px;
    }
  }
  .alert-image {
    margin-top: -7px;
  }
  .card {
    box-shadow: 0px 5px 10px rgba(35, 60, 134, 0.08);
  }
  .breadcrumb a {
    color: $secondary;
  }
  .close {
    opacity: 1;
    line-height: 25px;
  }
  .event-back {
    &:hover {
      background-color: $secondary;
      color: white;
    }
  }
  button:focus {
    outline: none;
  }
  .nav-link:focus {
    outline: none;
  }
  .sca-title {
    // height: 64px;
    // -webkit-line-clamp: 3;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
  .volunteer-image {
    width: 44px;
    height: 44px;
  }
  .aed-bg {
    width: 50px;
    height: 50px;
    background: #eaeaea;
  }
  .form-control.is-invalid:focus {
    box-shadow: none;
    border-top-color: $secondary;
    border-left-color: $secondary;
    border-right-color: $secondary;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-width: 1px 1px 1px 1px;
  }
  .form-control.is-invalid {
    border-width: 0px 0px 1px 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .eye-icon {
    position: absolute;
    right: 9px;
    top: 18px;
  }
  input.rbt-input-hint {
    display: none;
  }
  .pagination-ellipse {
    .page-link {
      span {
        font-size: 19.6px;
      }
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .page-link {
    span {
      font-size: 19.6px;
    }
  }
  .btn-primary {
    &:hover {
      background: $secondary;
    }
    &:focus {
      background: $primary;
      border-color: $primary;
      box-shadow: none;
    }
  }
  .btn-dark {
    &:hover {
      background: $btn-dark-hover;
    }
    &:focus {
      background: $btn-dark-hover;
      border-color: $btn-dark-hover;
      box-shadow: none;
    }
  }
  .bg-btn-disable {
    background: $input-disabled-bg;
  }
  .custom-select {
    background: #f2f4fc;
    cursor: pointer;
    background-image: url('../assets/images/down.png');
    background-repeat: no-repeat;
    background-position: 97% 54%;
  }
  .custom-select {
    &:focus {
      background-color: #ffffff;
      box-shadow: none;
    }
  }
  .tab-profile {    
    border-radius: 50% !important;
  }
  .location-map {
    width: 40px;
  }
  .custom-check {
    .custom-control-label::before {
      background: #ffffff;
      border: 1px solid #e7e5ff;
    }
  }
  .city-checked::before {
    content: '';
    height: 109px;
    position: absolute;
    border-right: 2px solid #cccccc;
    right: -1px;
  }
  .page-item.disabled .page-link {
    color: $secondary;
  }
  .page-link {
    padding: 3px 8px;
  }
  .page-item {
    border-radius: 2px;
  }
  .email-bg,
  .contact-number-field {
    background-image: url('../assets/images/icon_radio-check (2)_1 (1).svg') !important;
    background-repeat: no-repeat !important;
    background-position: 95% 56% !important;
    background-size: auto !important;
    padding-right: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .react-form-label {
    font-size: 14px;
  }
  .form-label-group > .react-dropdown-label {
    top: -9px;
    font-size: 14px;
    color: $info;
    z-index: 1;
    width: auto;
    height: 0px;
  }
  .user-privilege-dropdown {
    height: calc(1.7em + 1.8rem + 4px);
    background: #f2f4fc;
    border: 1px solid #ffffff;
    &:hover {
      border: 1px solid #ffffff;
    }
  }
  .state-dropdown {
    // height: calc(1.7em + 1.8rem + 4px);
    background: #f2f4fc;
    border: 1px solid #ffffff;
    &:hover {
      border: 1px solid #ffffff;
    }
    &:focus {
      border: 1px solid red;
    }
  }
  .css-wmw4vi-ReactDropdownSelect:focus-within {
    background: #ffffff;
    border: 1px solid $secondary;
  }
  .badge-selected {
    background-color: $primary;
    color: #ffffff;
  }
  /** notification design **/
  .Toastify__toast-container {
    // width: 278px;
    .Toastify__toast--dark {
      background-color: $body-color;
      border-radius: 4px;
      font-family: $font-family-base;
    }
    .Toastify__close-button {
      opacity: 1;
      margin-top: -2px;
      svg {
        width: 10px;
        margin-top: -12px;
      }
    }
  }
  .rbt-input-main {
    padding-right: 42px;
  }
  /** Input file design **/
  .SpreadsheetGridContainer {
    width: 3500px;
  }
  .SpreadsheetGrid__headCell {
    background-color: #ffffff;
    color: $info;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    font-size: 16px;
    font-family: $font-family-base;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    padding: 17px 15px;
  }
  .SpreadsheetGrid__cell_active {
    box-shadow: inset 0 0 0 1px $secondary;
  }
  .SpreadsheetGridInput {
    font-size: 16px;
    color: $headings-color;
    font-family: $font-family-base;
  }
  .SpreadsheetGrid__cell {
    border-right: 1px solid #efefef;
  }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    display: none;
  }
  .file-text .custom-file-label {
    border: 1px dashed $secondary;
    border-radius: 4px;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::before {
      content: 'Tap to upload/click photos from your device';
      position: absolute;
      top: 32px;
      right: 58px;
      bottom: 0;
      z-index: 3;
      display: block;
      color: #233c86;
      font-family: $font-family-base;
      font-size: $h5-font-size;
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }
  .import-file .custom-file-label {
    padding-left: 4%;
    padding-right: 10%;
  }
  .file-csv-text .custom-file-label {
    border: 1px dashed $secondary;
    border-radius: 4px;
    padding-top: 15px;
  }
  .delete-icon {
    position: absolute;
    right: 29px;
    z-index: 3;
    top: 15px;
    font-size: -5px;
    width: 29px;
  }
  .table-data {
    table {
      &:focus {
        outline: none;
      }
      border-collapse: collapse;
      overflow-x: auto;
      table,
      th,
      td {
        border: 1px solid #efefef;
      }
      th {
        padding: 6px 9px;
        height: 35px;
        color: $info;
      }
    }
    position: relative;
    height: 384px;
    .reactTableCell {
      width: 116px;
      height: 35px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 6px 9px;
    }
    .mousetrap {
      border: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 6px 9px;
      position: relative;
      &:focus {
        outline: none;
      }
    }
  }
  .assign-aed {
    overflow-x: hidden;
    overflow-y: auto;
    height: 450px;
  }
}
::selection {
  background-color: #d9e0ef;
}
.custom-invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8125rem;
  color: #dc3545;
}

.dashboard-image {
  box-shadow: 0px 5px 10px rgba(35, 60, 134, 0.08);
}
.daterangepicker td.start-date.end-date {
  border-radius: 100% !important;
  color: white;
}
.daterangepicker .calendar-table tr td {
  color: $headings-color;
  border-radius: 100%;
  font-size: 16px;
  // line-height: 35px;
}
.daterangepicker th.month {
  color: $secondary !important;
  font-size: 16px;
  font-family: $headings-font-family;
}
.daterangepicker .calendar-table th {
  color: $info;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}
.date {
  background-image: url('../assets/images/down.png');
  background-position: center right;
  background-repeat: no-repeat;
  font-size: 16px;
}
.daterangepicker.show-calendar .drp-buttons {
  display: none;
}
.daterangepicker {
  font-family: $font-family-base;
  box-shadow: 0px 5px 10px rgba(35, 60, 134, 0.08);
  width: 350px !important;
}
.daterangepicker td.off {
  color: $info;
}
.calendar-table {
  width: 337px !important;
}
.table-condensed td {
  padding-top: 10px;
  padding-bottom: 10px;
}
// .responsive-iframe {
//   top: -712px !important;
//   left: 0% !important;
//   bottom: 0;
//   right: 0;
//   width: 100% !important;
//   height: 100% !important;
//   padding: 120px;
// }
.mob-container {
  // position: relative;
  // width: 100%;
  // height: 100%;
  // overflow: hidden;
  /* padding-top: 216.5%; 16:9 Aspect Ratio */
  // border: 1px solid black;
  // background: url('./Mobile_with_White_box.svg');
}
​ .mob-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  border: none;
}
.rescue-link {
  text-decoration: underline;
  font-weight: bold;
  font-size: 18px;
}
@import './media.scss';
@import './device.min.scss';
