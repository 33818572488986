body {
  @media (min-width: $screen-xs-min) {
    .sidebar {
      position: relative;
      width: 100%;
    }
    .demo-description {
      text-align: center;
    }
    .demo-header {
      float: none !important;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 781px) {
    .laptop-frame {
      position: absolute !important;
      height: 100% !important;
      top: -55px !important;
      padding: 70px !important;
      width: 100% !important;
      left: 2px !important;
    }
  }
  @media screen and (min-width: 765px) and (max-width: 767px) {
    .laptop-frame {
      // position: absolute !important;
      // height: 100% !important;
      // top: -53px !important;
      // padding: 66px !important;
      // left: 0px !important;
      // position: absolute !important;
      // height: 96% !important;
      // top: -48px !important;
      // padding: 63px !important;
      // left: 0px !important;

      // position: absolute !important;
      // height: 94% !important;
      // top: 4px !important;
      // padding: 21px !important;
      // width: 120% !important;
      // left: -53px !important;
      position: absolute !important;
      height: 78% !important;
      top: -4px !important;
      padding: 21px !important;
      width: 120% !important;
      left: -52px !important;
    }
  }
  @media screen and (min-width: 782px) and (max-width: 991px) {
    .laptop-frame {
      position: absolute !important;
      width: 100% !important;
      height: 582px !important;
      top: -184px !important;
      padding: 64px !important;
      left: 3px;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1023px) {
    .laptop-frame {
      // position: absolute !important;
      // height: 100% !important;
      // padding: 47px !important;
      // top: -17px !important;
      // position: absolute !important;
      // height: 100% !important;
      // padding: 30px !important;
      // top: -8px !important;
      position: absolute !important;
      height: 100% !important;
      padding: 66px !important;
      top: -44px !important;
    }
  }
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    /* Styles */
    // .mobile-iframe {
    //   height: 362px !important;
    //   width: 169px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 23.4%;
    //   bottom: -0.2%;
    //   top: -11.5%;
    // }
  }

  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width: 321px) and (orientation: landscape) {
    /* Styles */
    // .mobile-iframe {
    //   height: 405px !important;
    //   width: 188px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 32.5%;
    //   bottom: -0.2%;
    //   top: -10%;
    // }
  }

  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width: 320px) and (orientation: portrait) {
    /* Styles */
    // .mobile-iframe {
    //   height: 362px !important;
    //   width: 170px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 23.5%;
    //   bottom: -0.2%;
    //   top: -11.5%;
    // }
  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Styles */
    // .mobile-iframe {
    //   height: 543px !important;
    //   width: 224px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 23.4%;
    //   bottom: -0.2%;
    //   top: -14%;
    // }
  }

  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* Styles */
    // .mobile-iframe {
    //   height: 483px !important;
    //   width: 229px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 32%;
    //   bottom: -0.2%;
    //   top: -8%;
    // }
    .laptop-frame {
      // position: absolute;
      // width: 100%;
      // height: 100%;
      // padding: 45px;
      // top: -16px;
      position: absolute;
      width: 100%;
      height: 75%;
      padding: 47px;
      top: -27px;
      // position: absolute;
      // width: 100%;
      // height: 100vh;
      // padding: 68px;
      // top: -98px;
      // left: 0;
    }
  }

  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* Styles */
    .laptop-frame {
      // position: absolute;
      // width: auto;
      // height: 100%;
      // padding: 59px;
      // top: -74px;
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 67px;
      top: -53px;
      left: 0px;
    }
    // .laptop-frame {
    //   position: absolute;
    //   height: 69%;
    //   top: -65px;
    // }
    // .mobile-iframe {
    //   height: 543px !important;
    //   width: 224px !important;
    // }
    // .mobile-iframe {
    //   height: 617px !important;
    //   width: 278px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 23.4%;
    //   bottom: -0.2%;
    //   top: -14%;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 121px;
    //   bottom: -0.2%;
    //   top: -43px;
    // }
  }

  /* Desktops and laptops ----------- */
  @media only screen and (min-width: 1224px) {
    /* Styles */
    // .mobile-iframe {
    //   height: 583px !important;
    //   width: 267px !important;
    // }
  }

  /* Large screens ----------- */
  @media only screen and (min-width: 1824px) {
    /* Styles */
    // .mobile-iframe {
    //   height: 583px !important;
    //   width: 267px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 32.4%;
    //   bottom: -0.2%;
    //   top: -7%;
    // }
  }

  /* iPhone 4 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
    // .mobile-iframe {
    //   height: 358px !important;
    //   width: 167px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 32.5%;
    //   bottom: -0.2%;
    //   top: -12%;
    // }
  }

  /* iPhone 5 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    // .mobile-iframe {
    //   height: 428px !important;
    //   width: 197px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 32.5%;
    //   bottom: -0.2%;
    //   top: -10%;
    // }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) {
    /* Styles */
    // .mobile-iframe {
    //   height: 362px !important;
    //   width: 170px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 23.5%;
    //   bottom: -0.2%;
    //   top: -11.5%;
    // }
    .laptop-frame {
      // position: absolute;
      // height: 74%;
      // top: -28px;
    }
  }

  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    // .mobile-iframe {
    //   height: 433px !important;
    //   width: 199px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 23.5%;
    //   bottom: -0.2%;
    //   top: -10%;
    // }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1239px) {
    .laptop-frame {
      // position: absolute;
      // padding: 45px;
      // width: 100%;
      // height: 100%;
      // top: -17px;
      // position: absolute;
      // padding: 37px;
      // width: 100%;
      // height: 95%;
      // top: -12px;

      // position: absolute;
      // padding: 27px;
      // width: 100%;
      // height: 95%;
      // top: 3px;
      position: absolute;
      padding: 33px;
      width: 100%;
      height: 80%;
      top: -7px;
    }
  }

  @media (min-width: $screen-sm-min) {
    .sidebar {
      position: relative;
      width: 100%;
    }
    .demo-description {
      text-align: center;
    }
    .demo-header {
      float: none !important;
    }
    // .responsive-iframe {
    //   top: -588px !important;
    //   padding: 97px !important;
    //   height: 672px !important;
    // }
    // .mobile-iframe {
    //   height: 500px !important;
    //   width: 350px !important;
    // }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: $screen-md-min) {
    .sidebar {
      width: 330px;
    }
    .demo-description {
      text-align: left;
      height: 331px;
    }
    .demo-header {
      float: right !important;
    }

    // .mobile-iframe {
    //   height: 543px !important;
    //   width: 224px !important;
    // }
    // .mobile-frame {
    //   position: absolute;
    //   left: 23.4%;
    //   bottom: -0.2%;
    //   top: -14%;
    // }
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: $screen-lg-min) {
    .sidebar {
      width: 370px;
    }
    .demo-description {
      text-align: left;
    }
    .demo-header {
      float: right !important;
    }
  }
  @media (min-width: $screen-xl-min) {
    .sidebar {
      width: 350px;
    }
  }
}
