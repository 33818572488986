/** Define font family */
@font-face {
  font-family: 'Futura-PT-Book';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local('FuturaPTBook'),
    url(../assets/fonts/FuturaPTBook.otf) format('opentype');
}

@font-face {
  font-family: 'Futura-PT-Bold';
  font-style: normal;
  font-display: auto;
  font-weight: 700;
  src: local('FuturaPTBold'),
    url(../assets/fonts/FuturaPTBold.otf) format('opentype');
}

@font-face {
  font-family: 'Futura-PT-Medium';
  font-weight: 500;
  font-display: auto;
  font-style: normal;
  src: local('FuturaPTMedium'),
    url(../assets/fonts/FuturaPTMedium.otf) format('opentype');
}

@font-face {
  font-family: 'Futura-PT-Light';
  font-style: normal;
  font-display: auto;
  font-weight: 300;
  src: local('FuturaPTLight'),
    url(../assets/fonts/FuturaPTLight.otf) format('opentype');
}

/* Global Font style and Font Family */

$font-family-base: 'Futura-PT-Book';
$font-family-monospace: 'Futura-PT-Book';
$headings-font-family: 'Futura-PT-Bold';
$input-btn-font-family: 'Futura-PT-Medium';
$input-font-family: 'Futura-PT-Book';
/* h1 to h6 font size */

$h1-font-size: 2.5rem;
$h2-font-size: 1.563rem;
$h3-font-size: 1.125rem;
$h4-font-size: 1rem;
$h5-font-size: 0.8125rem;
$h6-font-size: 0.75rem;
$code-font-size: 1rem;
